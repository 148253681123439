import React, {useState} from "react";
import LoginForm from "./LoginForm";
import queryString from "query-string";
import cookie from "react-cookie";
import Logo from "../../components/Logo";
import {Helmet} from "react-helmet";
import Dialog from "@material-ui/core/Dialog";
import "./style.styl";


export default function LoginWidget(props) {
    const {open, isMobile, onClose, dispatch} = props;
    return (
        <Dialog
            maxWidth={isMobile ? "sm" : "xs"}
            fullWidth
            open={open}
            onClose={onClose}

            >
                <LoginForm {...props} options={{}} onSuccess={() => {dispatch("/");onClose();}}/>
        </Dialog>
    );
}




const token = cookie.load("token");

export const login = {
    token: token === "undefined" ? "" : token
};

export const logout = (dispatch) => {
    cookie.remove("token", {path: "/"});
    cookie.remove("token_refresh", {path: "/"});
    if (dispatch) {
        dispatch();
    }
};
