import React, {useState} from "react";
import "./style.styl";


export default function ExtraHeader(props) {
    const {dispatch} = props;

    const text = (
        <div onClick={() => dispatch("/payment-sign-up")}>
            Sign Up for Stripe Payments
        </div>
    );

    return (
        <div className={"u-flexCenter u-justifyContentSpaceBetween u-cursorPointer u-paddingVertical5"} style={{backgroundColor: "#ffe4f0"}}>
            <div className="container">
                <div className="u-flexCenter u-justifyContentCenter u-reative link">
                    {text}
                </div>
            </div>
        </div>
    );
}
