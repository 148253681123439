import React from "react";

import { Main } from "./Main";

import { useDispatch } from "../../context/state";

import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";

export const SideMenu = (props) => {
  const { isOpen } = props;

  const dispatch = useDispatch();

  const onClose = () => dispatch("sideMenu", { isSideMenuOpen: false });

  const goToPage = (link) => {
    dispatch(link);
    onClose();
  };

  return (
    <SwipeableDrawer
      open={isOpen}
      onOpen={() => dispatch("sideMenu", { isSideMenuOpen: true })}
      onClose={onClose}
    >
      <div className="flex flex-col">
        <Main {...props} onClose={onClose} goToPage={goToPage} />
      </div>
    </SwipeableDrawer>
  );
};

export const sideMenu = {
  isSideMenuOpen: false,
};
