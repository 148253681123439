import React, {useState} from "react";
import Avatar from "@material-ui/core/Avatar";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import PowerSettingsNew from "@material-ui/icons/PowerSettingsNew";
import Person from "@material-ui/icons/Person";
import LoginWidget from "../../LoginWidget";
import cookie from "react-cookie";
import "./style.styl";


export default function AccountWidget(props) {
    const {open, isLogin, isPreLogin, customer, onClose, dispatch} = props;
    const [isLoginOpen, setIsLoginOpen] = useState(false);

    if (isPreLogin) {
        return (
            <React.Fragment>
                <Menu
                  anchorEl={open}
                  keepMounted
                  className="account-widget-menu"
                  open={Boolean(open)}
                  onClose={onClose}
                >
                <MenuItem onClick={() => {dispatch("/signup");onClose();}}>Continue</MenuItem>
                  <MenuItem onClick={() => {dispatch("login", {token: undefined});dispatch("customer", {delete:{}});dispatch("/");logout();onClose();}}>Sign Out</MenuItem>
                </Menu>
            </React.Fragment>
        );
    }

    if (!isLogin) {
        return (
            <React.Fragment>
                <Menu
                  anchorEl={open}
                  keepMounted
                  className="account-widget-menu"
                  open={Boolean(open)}
                  onClose={onClose}
                >
                  <MenuItem onClick={() => {dispatch("/login");onClose();}}>Sign In</MenuItem>
                  <MenuItem onClick={() => {dispatch("/signup");onClose();}}>Sign Up</MenuItem>
                </Menu>
                <LoginWidget {...props} open={isLoginOpen} onClose={() => setIsLoginOpen(false)}/>
            </React.Fragment>
        );
    }

    return (
        <Menu
          anchorEl={open}
          keepMounted
          className="account-widget-menu"
          open={Boolean(open)}
          onClose={onClose}
        >
            <div className="u-paddingHorizontal20 u-paddingTop10 u-flexColumn u-justifyContentCenter u-alignItemsCenter u-marginBottom10">
                <Avatar className="u-width60 u-height60">
                    E
                </Avatar>
                <span className="u-marginTop5 u-textColorNormal">
                    {customer.email || "email@gmail.com"}
                </span>
            </div>
          <MenuItem className="u-hide" onClick={() => {dispatch("/account");onClose();}}>Account</MenuItem>
          <MenuItem onClick={() => {dispatch("login", {token: undefined});dispatch("customer", {delete:{}});dispatch("/");logout();onClose();}}>Sign Out</MenuItem>
        </Menu>
    );
}


export const logout = (dispatch) => {
    cookie.remove("token", {path: "/"});
    cookie.remove("token_refresh", {path: "/"});
    if (dispatch) {
        dispatch();
    }
};
