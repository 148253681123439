import React from "react";
import {Logo} from "../../components/Logo";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import IconButton from "@material-ui/core/IconButton";
import ListItemText from "@material-ui/core/ListItemText";
import Call from "@material-ui/icons/Call";
import Email from "@material-ui/icons/Email";
import Send from "@material-ui/icons/Send";
import Chat from "@material-ui/icons/Chat";
import Twitter from "@material-ui/icons/Twitter";
import Facebook from "@material-ui/icons/Facebook";
import Instagram from "@material-ui/icons/Instagram";
import LinkedIn from "@material-ui/icons/LinkedIn";
import Link from "next/link";
import { useStore } from "../../context/state";
import { useRouter } from "../../hooks/useRouter";
import "./Footer.module.scss";

const menu = [
    {name: "Privacy Policy", link: "/privacy-policy"},
    {name: "Terms of Use", link: "/terms-and-conditions"},
    {name: "Terms of Use Restaurant", link: "/terms-and-conditions-restaurant"}
];

const media = [
    {name: "Facebook", link: "/add", icon: "<TwitterIcon/>"},
    {name: "Twitter", link: "/about-us"},
    {name: "Instagram", link: "/media"},
    {name: "LinkedIn", link: "/support"}
];



export function Footer(props) {

    const { isMobile, dispatch } = useStore();

    return (
          <footer className="footer">
              <div className="container u-marginBottom20">
                  <div className="u-flexCenter u-justifyContentSpaceBetween u-xs-flexColumn">
                      <Logo  dispatch={dispatch}/>

                          <div className="copyright u-flexCenter u-justifyContentCenter u-xs-marginTop10">
                            <a href="https://www.facebook.com/catch22market" target="_blank">
                                <IconButton>
                                    <Facebook className="u-textColorDarker" />
                                </IconButton>
                            </a>
                            <a href="https://www.instagram.com/catch22market/" target="_blank">
                                <IconButton>
                                    <Instagram className="u-textColorDarker" />
                                </IconButton>
                            </a>
                            <a href="https://twitter.com/Catch22Market" target="_blank">
                                <IconButton>
                                    <Twitter className="u-textColorDarker" />
                                </IconButton>
                            </a>
                            <a href="https://www.linkedin.com/company/catch22delivery-com" target="_blank">
                                <IconButton>
                                    <LinkedIn className="u-textColorDarker" />
                                </IconButton>
                            </a>
                          </div>
                  </div>
              </div>

              <div className="container u-marginBottom30">
                  <div className="u-flexCenter u-justifyContentSpaceBetween u-xs-flexColumn">
                      <span>Contact us: support@fed.by</span>

                      <span>
                          <nav className="app-header-menu u-sizeFullWidth u-flex u-justifyContentSpaceBetween u-xs-marginTop20" >
                              {menu.map((v, i) => {
                                  if (v.isPrivate && !isLogin) {
                                      return null;
                                  }
                                  return (
                                      <Link href={v.link} key={i}>
                                        <a>
                                          <span
                                                className={"menu-item u-flexCenter u-cursorPointer u-marginLeft10 u-xs-marginLeft0"}>
                                              {v.name}
                                          </span>
                                        </a>
                                      </Link>
                                  );
                              })}
                          </nav>
                      </span>
                  </div>
              </div>

              <span className="u-flexCenter u-justifyContentCenter u-marginTop20 u-textAlignCenter u-textColorNormal u-sizeFullWidth">
                  Copyright © 2021 C22SMB, LLC - All Rights Reserved.
              </span>
          </footer>
    );

}
