import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Launch from "@material-ui/icons/Launch";
import Tooltip from "@material-ui/core/Tooltip";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import ProductDescription from "./ProductDescription";
import queryString from "query-string";
import { useStore } from "../../context/state";
import { useRouter } from "../../hooks/useRouter";
import Close from "@material-ui/icons/Close";
import "./style.styl";


const getStoreId = (path) => {
    if (!path) {
        return "";
    }
    if (path.endsWith("/")) {
        path = path.slice(0, -1);
    }
    const pathSplit = path.split("/");
    return pathSplit[pathSplit.length - 1];
};


export default function ProductDescriptionWidget(props) {
    const {isFullscreen} = props;
    const location = useRouter();
    const {isMobile, dispatch} = useStore();
    const params = queryString.parse(location.search);
    const productId = params.show_product;



    const ecommerceStoreId = params.show_product_ecommerce_store_id || props.ecommerceStoreId || getStoreId(location.pathname) || "";

    const onClose = () => {
        delete params["show_product"];
        delete params["show_product_ecommerce_store_id"];
        dispatch(location.pathname + "?"+ queryString.stringify(params));
    };

    const onOpenNewWindowLink = () => {
        delete params["show_product"];
        const link = "/product/" + productId + "?" + queryString.stringify(params);
        return link;
    };

    if (!productId) {
        return null;
    }

    return (
        <Dialog
            fullScreen={isFullscreen}
            maxWidth="sm"
            fullWidth
            className="tree-widget"
            aria-labelledby="confirmation-dialog-title"
            open={true}
            onClose={onClose}

            >
                <div>
                    <div className="u-flexCenter u-justifyContentSpaceBetween u-paddingVertical5">
                        <div className="u-flexCenter u-paddingLeft24">

                        </div>

                        <div className="u-flexCenter">
                            {
                                isMobile ?
                                    null
                                    :
                                    <Tooltip title="Open in new tab">
                                        <a style={{display: "table-cell"}} href={onOpenNewWindowLink()} target="_blank">
                                            <IconButton className="">
                                                <Launch/>
                                            </IconButton>
                                        </a>
                                    </Tooltip>
                            }
                            <IconButton
                                        onClick={onClose}
                                        className="u-marginRight12">
                                <Close/>
                            </IconButton>
                        </div>
                    </div>
                    <DialogContent>
                        <div className="u-paddingHorizontal24">
                            <ProductDescription {...props}  location={location} ecommerceStoreId={ecommerceStoreId} onClose={onClose} itemIdValue={productId}/>
                        </div>
                    </DialogContent>
                </div>
        </Dialog>
    );
}
