import Head from "next/head";

import { apiPath } from "../constants";
import { AppWrapper } from "../app";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { StoreWrapper as Provider } from "../context/state";

import "../globalStyles/medium.css";
import "../styles.css";

import "../globalStyles/style-general.scss";
import "../globalStyles/style.scss";
import "../globalStyles/theme.scss";

export default function App({ Component, pageProps }) {
  return (
    <>
      <Head>
        <link rel="preconnect" href={apiPath} />
        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;600;700;800;900&display=swap"
          rel="stylesheet"
        />
        <link href="https://fonts.googleapis.com/css2?family=Lobster+Two&display=swap" rel="stylesheet"/>
      </Head>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Provider>
            <AppWrapper>
              <Component {...pageProps} />
            </AppWrapper>
          </Provider>
      </MuiPickersUtilsProvider>
    </>
  );
}
