import React from "react";
import cookie from "react-cookie";
import Link from "next/link";
import Button from "../../../components/Button";
import {Logo} from "../../../components/Logo";
import { useStore } from "../../../context/state";
import { useRouter } from "../../../hooks/useRouter";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import IconButton from "@material-ui/core/IconButton";
import Avatar from "@material-ui/core/Avatar";
import Person from "@material-ui/icons/Person";
import Twitter from "@material-ui/icons/Twitter";
import Facebook from "@material-ui/icons/Facebook";
import Instagram from "@material-ui/icons/Instagram";
import "./Main.module.scss";


export const Main = (props) => {
    const {onClose} = props;
  const { isLogin, token, dispatch, customer } = useStore();
  const location = useRouter();

  const pathname = location.pathname.split("?")[0].replace("/", "").replace("/", "");


  return (
    <List className="side-bar-custom u-width260">

        <div className="u-flexCenter u-justifyContentCenter u-marginTop20" onClick={onClose}>
            {
                !isLogin ?
                    <Link href="/login">
                      <a>
                        <Button type="outlined" color="secondary" className="u-marginTop20">
                          Sign In
                        </Button>
                      </a>
                    </Link>
                    :
                    <div className="u-paddingHorizontal20 u-paddingTop10 u-flexColumn u-justifyContentCenter u-alignItemsCenter u-marginBottom0">
                        <Avatar className="u-width60 u-height60 u-backgroundColorTransparent" style={{border: "1px solid #C7073D"}}>
                            <Person fontSize="large" className="u-textColorSecondary"/>
                        </Avatar>
                        <span className="u-marginTop5 u-textColorNormal">
                            {customer.email || ""}
                        </span>
                    </div>
            }

        </div>

      <div className="menu u-flexColumn u-sizeFullWidth u-marginTop50">
        <Link href="/">
          <span className={"u-textAlignCenter u-fontWeightBold u-textColorBlack u-fontSize18 u-marginBottom10 u-cursorPointer u-paddingHorizontal24 u-paddingVertical8 " + (pathname === "" ? "selected" : "")} onClick={onClose}>
            Market
          </span>
        </Link>
        <Link href="/about">
          <span className={"u-textAlignCenter u-fontWeightBold u-textColorBlack u-fontSize18 u-marginBottom10 u-cursorPointer u-paddingHorizontal24 u-paddingVertical8 " + (pathname === "about" ? "selected" : "")} onClick={onClose}>
            About Us
          </span>
        </Link>
        {/* <Link href="/blog">
          <span className={"u-textAlignCenter u-fontWeightBold u-textColorBlack u-fontSize18 u-marginBottom10 u-cursorPointer u-paddingHorizontal24 u-paddingVertical8 " + (pathname === "blog" ? "selected" : "")} onClick={onClose}>
            Blog
          </span>
        </Link>
        <Link href="/business">
          <span className={"u-textAlignCenter u-fontWeightBold u-textColorBlack u-fontSize18 u-marginBottom10 u-cursorPointer u-paddingHorizontal24 u-paddingVertical8 " + (pathname === "business" ? "selected" : "")} onClick={onClose}>
            For Businesses
          </span>
        </Link>
        <Link href="/customers">
          <span className={"u-textAlignCenter u-fontWeightBold u-textColorBlack u-fontSize18 u-marginBottom10 u-cursorPointer u-paddingHorizontal24 u-paddingVertical8 " + (pathname === "customers" ? "selected" : "")} onClick={onClose}>
            For Customers
          </span>
        </Link> */}
        <Link href="/business-sign-up">
          <span className={"u-textAlignCenter u-fontWeightBold u-textColorBlack u-fontSize18 u-marginBottom40 u-cursorPointer u-paddingHorizontal24 u-paddingVertical8 " + (pathname === "business-sign-up" ? "selected" : "")} onClick={onClose}>
             Business Sign Up
          </span>
        </Link>
      </div>


      {
          !isLogin ?
              <div>

                  <ListItem className="u-flexCenter u-justifyContentCenter u-marginTop5">
                    <Link href="/sign-up">
                      <a>
                        <Button size="large" color="secondary" className="" onClick={onClose}>
                          Sign Up
                        </Button>
                      </a>
                    </Link>
                  </ListItem>
              </div>
              :
              <div>
                  <ListItem className="u-flexCenter u-justifyContentCenter u-marginTop5">
                      <Button size="large" color="secondary" type="outlined" className="" onClick={() => {dispatch("login", {token: undefined});dispatch("customer", {delete:{}});dispatch("/");logout();onClose();}}>
                        Sign Out
                      </Button>
                  </ListItem>
              </div>
      }

      <div className="u-flexCenter u-justifyContentCenter u-marginTop30">
        <a href="https://twitter.com/Catch22Market" target="_blank">
            <IconButton>
                <Twitter className="u-textColorSecondary u-fontSize28" />
            </IconButton>
        </a>
        <a href="https://www.instagram.com/catch22market/" target="_blank">
            <IconButton className="">
                <Instagram className="u-textColorSecondary u-fontSize28" />
            </IconButton>
        </a>
        <a href="https://www.facebook.com/catch22market" target="_blank">
            <IconButton>
                <Facebook className="u-textColorSecondary u-fontSize28"/>
            </IconButton>
        </a>
      </div>


    </List>
  );
};

export const logout = (dispatch) => {
  cookie.remove("token", { path: "/" });
  cookie.remove("token_refresh", { path: "/" });
  if (dispatch) {
    dispatch();
  }
};
