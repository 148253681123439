import React, { useEffect } from "react";
import cookie from "react-cookie";
import { useRouter } from "next/router";
import {Logo} from "../components/Logo";

import { Header } from "../components/Header";
import { Footer } from "../components/Footer";
import { Cart } from "../components/Cart";
import { SideMenu } from "../components/SideMenu";
import { locationItems } from "../components/LocationWidget";
import { useStore } from "../context/state";
import { processIpMeta, calculateDistance } from "commons-ui/core/Fingerprint";
import {
  ecommerceStoreGroupId,
  ecommerceStoreId,
  blogEcommerceStoreId,
  blogEcommerceStoreGroupId,
  apiPath,
  mediaPath,
  socketPath,
} from "../constants";

import ProductDescriptionWidget from "../components/ProductDescriptionWidget";

import { dataSourceV2 } from "commons-ui/core/DataSource";
import DialogCore from "commons-ui/core/Dialog";

const logout = (dispatch) => {
  cookie.remove("token", { path: "/" });
  cookie.remove("token_refresh", { path: "/" });
  if (dispatch) {
    dispatch();
  }
};

export const AppWrapper = (props) => {
  const {
    style,
    sideMenu,
    device,
    cart,
    store_settings,
    category_widget,
    location_widget,
    dialog_core,
    customer,
    login,
    dispatch,
  } = useStore();

  const isLogin =
    customer.status === "payment" || customer.status === "confirmation"
      ? false
      : login.token;

  const isPreLogin = login.token && !isLogin;

  const onResize = () => {
    const width = window.innerWidth;
    const height = window.innerHeight;
    const isMobile = width < 800;
    dispatch("style", { width, height, isMobile });
  };

  const checkRequiredData = () => {
    if (!login.token) {
      return;
    }

    dataSourceV2("customer_get", { url: apiPath }).then((v) => {
      if (v.item) {
        //dispatch("customer", v.item);
      } else if (v.status) {
        logout(() => dispatch("/"));
      }
    });
  };

  useEffect(() => {
    onResize();
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  useEffect(() => {
    checkRequiredData();
  }, [isLogin]);

  useEffect(() => {
    checkRequiredData();
  }, []);

  useEffect(() => {
    if (customer.force_update) {
      checkRequiredData();
    }
  }, [customer.force_update]);


  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        dispatch("location_widget", {
          latitude: position.coords.latitude.toString(),
          longitude: position.coords.longitude.toString(),
        });
      },
      (error) => {
        processIpMeta({}).then((v) =>
          dispatch("location_widget", {
            latitude: v.ip_lat.toString(),
            longitude: v.ip_lon.toString(),
          })
        );
      }
    );
  }, []);

  useEffect(() => {
    if (!location_widget.latitude || !location_widget.longitude) return;
    let locationSelected = "WA";
    let stateLatitude = "";
    let stateLongitude = "";
    let distanceMin = 100000;
    locationItems.forEach((item, i) => {
      if (item.lat && item.lon) {
        const distance = calculateDistance(
          item.lat,
          item.lon,
          location_widget.latitude,
          location_widget.longitude
        );
        if (distance < distanceMin) {
          distanceMin = distance;
          locationSelected = item.value;
          stateLatitude = item.lat;
          stateLongitude = item.lon;
        }
      }
    });
    if (distanceMin !== 100000) {
      dispatch("location_widget", {
        stateSelected: locationSelected,
        stateDistance: distanceMin,
        stateLatitude,
        stateLongitude,
      });
    }
  }, [location_widget.latitude, location_widget.longitude]);

  const location = useRouter();
  const url = location.pathname;
  const mobileClass = style.isMobile ? " mobile" : "";
  const deviceId = device.device_id;
  const deviceSimpleId = device.device_simple_id;

  const isDark = "";

  const isMobile = style.isMobile;
  if (isMobile === undefined) {
    return null;
  }


  const propsDefault = {
    ...login,
    ...style,
    dispatch,
    device,
    store_settings,
    cart,
    isLogin,
    location_widget,
    category_widget,
    deviceId,
    customer,
    ecommerceStoreGroupId,
    ecommerceStoreId,
    blogEcommerceStoreId,
    blogEcommerceStoreGroupId,
    deviceSimpleId,
    mediaPath,
    apiPath,
    socketPath,
  };


  return (
    <div className={url === "/" ? isDark : ""}>
      <div style={{ minHeight: style.height }}>
        <SideMenu
          isOpen={(sideMenu || {}).isSideMenuOpen}
          url={url}
          isPreLogin={isPreLogin}
          isLogin={isLogin}
          dispatch={dispatch}
        />

    {/*<div style={{position:"absolute", marginBottom:"-100px", marginTop:"10px"}} className="container u-relative">
                    <div className="app-header-wrapper u-height60">
                        <div className="u-absolute0 u-sizeFull">
                            <Logo color={"white"} dispatch={dispatch}  noText={isMobile}/>
                        </div>
                      </div>
                  </div>*/}
        <Header
          {...propsDefault}
          isPreLogin={isPreLogin}
          color={url.split("?")[0] === "/" || url.split("?")[0] === "" ? "dark" : ""}
          productInCartCount={cart.items.reduce(
            (a, b) => a + (b.count || 1),
            0
          )}
          url={url}
        />

        <div
          className={"app-content u-paddingTop80" + mobileClass}
          style={{ minHeight: style.height }}
        >
          {props.children}
        </div>

        <Footer
          {...propsDefault}
         />

        <DialogCore
          {...dialog_core}
          fullScreen={isMobile}
          dispatch={dispatch}
        />

        <ProductDescriptionWidget
          {...props}
          {...propsDefault}
          dispatch={dispatch}
          location={location}
          isFullscreen={isMobile}
        />

        <Cart {...cart} {...propsDefault} />
      </div>
    </div>
  );
};
