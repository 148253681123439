import React, {useState} from "react";
import Notes from "@material-ui/icons/Notes";
import ShoppingCart from "@material-ui/icons/ShoppingCart";
import Whatshot from "@material-ui/icons/Whatshot";
import Face from "@material-ui/icons/Face";
import Person from "@material-ui/icons/Person";
import Menu from "@material-ui/icons/Menu";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import Loyalty from "@material-ui/icons/Loyalty";
import IconButton from "@material-ui/core/IconButton";
import Button from "../Button";
import Build from "@material-ui/icons/Build";
import Badge from "@material-ui/core/Badge";
import Avatar from "@material-ui/core/Avatar";
import Tooltip from "@material-ui/core/Tooltip";
import Add from "@material-ui/icons/Add";
import AccountWidget from "./AccountWidget";
import {Logo} from "../Logo";
import InfoMenu from "./InfoMenu";
import LocationWidget from "../LocationWidget";
import ExtraHeader from "./ExtraHeader";
import Link from "next/link";
import {getFromLS} from "commons-ui/core/utils/index";
import "./Header.module.scss";


const menu = [
    //{name: "Market", link: "/"},
    //{name: "ABOUT US", link: "/about-us"},
    //{name: "About Us", link: "/about-us"},
    //{name: "Add Restaurant", link: "/add"},
    //{name: "FAQ", link: "/support"}
];
export function Header(props) {
    const {url, ecommerceStore, customer, color, location_widget, productInCartCount, city, rootClassName, isCartOpen, isLogin, isMobile, isDrawerRight, disableCart, apiPath, dispatch} = props;
    const [accountOpen, setAccountOpen] = useState(null);
    const [signUpState, setSignUpState] = useState(getFromLS("businessSignUp") || {});


    if (isMobile) {
        return (
            <header className={"app-header-main u-absolute u-flexColumn u-justifyContentSpaceBetween" + rootClassName + (color === "dark" ? " dark " : "")}>
                {
                    signUpState.ecommerce_store_id && !signUpState.stripe_account_is_ready ?
                        <ExtraHeader dispatch={dispatch}/>
                        :
                        null
                }
                <div className="container u-relative">
                    <div className="app-header-wrapper u-height60 u-flexCenter u-justifyContentSpaceBetween u-reative">
                        <div className="u-absolute0 u-sizeFull u-flexCenter u-justifyContentCenter">
                            <Logo dispatch={dispatch} color={color === "dark" ? "white" : ""} noText={isMobile}/>
                        </div>
                        <IconButton color="inherit" className="u-marginLeftNegative12" onClick={() => dispatch("sideMenu", {isSideMenuOpen: true})}>
                            <Menu fontSize="large" className={(color === "dark" ? "u-textColorWhite" : "u-textColorSecondary")}/>
                        </IconButton>
                        <div>
                            <Button color="secondary" variant="outlined" isWhite={color === "dark"}  className="" disabled={isCartOpen || disableCart} onClick={() => dispatch("cart", {isOpen: true}, "open_cart")}>
                                    <ShoppingCart/> <span className="u-marginLeft5 u-fontWeightBold">{productInCartCount || 0}</span>

                            </Button>
                        </div>
                        {/*<LocationWidget selected={location_widget.stateSelected} className="u-marginLeft20" isMobile={isMobile} dispatch={dispatch}/>*/}
                        {/*<IconButton className="login-button" color="inherit" onClick={(event) => setAccountOpen(event.currentTarget)}>
                            <Person className=""/>
                        </IconButton>
                        <AccountWidget {...props} isLogin={isLogin} open={accountOpen} onClose={() => setAccountOpen(null)}/>*/}
                    </div>
                </div>
            </header>
        );
    }

    const menuWidget = (
        <nav className="app-header-menu u-flex u-paddingHorizontal10" >
            {menu.map((v, i) => {
                if (v.isPrivate && !isLogin) {
                    return null;
                }
                return (
                    <MenuItem   key={i}
                                link-meta={v.link}
                                onClick={() => dispatch(v.link)}
                                isActive={url === v.link || (v.match || []).includes(url)}
                                name={v.name}/>
                );
            })}
        </nav>
    );

    return (
        <header className={"app-header-main u-absolute u-flexColumn u-justifyContentSpaceBetween" + rootClassName + (color === "dark" ? " dark " : "")}
                >
                {
                    signUpState.ecommerce_store_id && !signUpState.stripe_account_is_ready ?
                        <ExtraHeader dispatch={dispatch}/>
                        :
                        null
                }
                <div className="container">
                    <div className="app-header-wrapper u-height80 u-flexCenter">
                        <div className="u-hide u-absolute0 u-sizeFull u-flexCenter u-justifyContentCenter" style={{margin: "0 auto"}}>

                        </div>

                        <div className="app-header-logo u-flexCenter u-xs-hide u-zIndex2">
                            <IconButton size="large" className="u-marginLeftNegative12 u-marginRight10" onClick={() => dispatch("sideMenu", {isSideMenuOpen: true})}>
                                <Notes fontSize="large" className={"icon-menu " + (color === "dark" ? "u-textColorWhite" : "u-textColorSecondary")}/>
                            </IconButton>

                            <Logo dispatch={dispatch} color={color === "dark" ? "white" : ""} noText={isMobile}/>
                            {/*<LocationWidget selected={location_widget.stateSelected} className="u-marginLeft20" isMobile={isMobile} dispatch={dispatch}/>*/}
                        </div>




                        <div className="u-flexCenter ">
                            {menuWidget}
                            {/*<Button className="button-primary u-hide" size="small" onClick={() => dispatch("/donate")}>
                                Donate
                            </Button>*/}

                            {/*<Tooltip title={isLogin ? "Account" : "Login"}>
                                <IconButton className="login-button" color="inherit" onClick={(event) => setAccountOpen(event.currentTarget)}>
                                    <Person className=""/>
                                </IconButton>
                            </Tooltip>
                            <AccountWidget {...props} isLogin={isLogin} open={accountOpen} onClose={() => setAccountOpen(null)}/>*/}

                            <AccountWidget {...props} isLogin={isLogin} open={accountOpen} onClose={() => setAccountOpen(null)}/>
                            <Button color="secondary" isWhite={color === "dark"} variant="outlined" className="u-marginRight15" disabled={isCartOpen || disableCart} onClick={() => dispatch("cart", {isOpen: true}, "open_cart")}>
                                    <ShoppingCart/> <span className="u-marginLeft8 u-fontWeightBold">{productInCartCount}</span>
                            </Button>

                            {
                                isLogin ?
                                    <Tooltip title={isLogin ? "Account" : "Login"}>
                                        <IconButton className="u-padding8" style={{border: (color === "dark" ? "1px solid white" : "1px solid #C7073D")}} onClick={(event) => setAccountOpen(event.currentTarget)}>
                                            <Person className={(color === "dark" ? "u-textColorWhite" : "u-textColorSecondary")}/>
                                        </IconButton>
                                    </Tooltip>
                                    :
                                    <div>
                                        <Link href="/sign-up">
                                          <a>
                                            <Button color="secondary" isWhite={color === "dark"} className="u-marginRight15">
                                              Sign Up
                                            </Button>
                                          </a>
                                        </Link>

                                        <Link href="/login">
                                          <a>
                                            <Button type="outlined" color="secondary" isWhite={color === "dark"} className="">
                                               Sign In
                                            </Button>
                                          </a>
                                        </Link>
                                    </div>
                            }





                        </div>
                    </div>
                </div>

        </header>
    );
}

function MenuItem(props) {
    const activeClass = props.isActive ? " active" : "";
    return (
        <span onClick={props.onClick}
              className={"menu-item u-flexCenter" + activeClass + " " + (props.className || "")}>
            {props.name}
        </span>
    );
}
