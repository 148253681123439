import React from "react";
import {Form, withFormik} from "formik";
import Button from "@material-ui/core/Button";
import {dataSourceV2} from "commons-ui/core/DataSource";
import cookie from "react-cookie";
import "./style.styl";
import TextField from "@material-ui/core/TextField";

export const emailRe = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;


function LoginForm(props) {
    const {values, errors, touched, isSubmitting, onClose, setFieldValue, dispatch} = props;
    return (
            <Form noValidate="" className="u-padding25" autocomplete="off">
                <div className="u-flexColumn u-alignItemsCenter u-marginBottom15">
                    <div className="u-fontSize28 u-fontWeightBold">
                        Sign In
                    </div>
                    <div className="comment">
                        Dont have an account ? <span className="link" link-meta="/login" onClick={() => {dispatch("/signup");onClose();}}>Sign Up</span>
                    </div>
                </div>
                <div className="u-marginTop15">
                    <TextField
                        error={errors.email && touched.email}
                        label="Email  "
                        helperText={errors.email && touched.email ? errors.email : ""}
                        margin="normal"
                        autocomplete="off"
                        autoComplete="new-password"
                        className="u-sizeFullWidth u-height60"
                        onChange={(e) => setFieldValue("email", e.target.value)}
                        value={values.email}
                    />
                </div>
                <div className="">
                    <TextField
                        error={errors.password && touched.password}
                        label="Password  "
                        helperText={errors.password && touched.password ? errors.password : ""}
                        margin="normal"
                        type="password"
                        autocomplete="off"
                        autoComplete="new-password"
                        className="u-sizeFullWidth u-height60"
                        onChange={(e) => setFieldValue("password", e.target.value)}
                    />
                </div>
                <div className="u-flexCenter u-justifyContentCenter u-marginVertical10">
                    <Button variant="outlined" type="submit" size="large" className="button-submit" disabled={isSubmitting}>
                        Sign In
                    </Button>
                </div>
            </Form>
    );
}


export default LoginForm = withFormik({
    mapPropsToValues: (props) => ({
        email: props.options ? props.options.email || "" : "",
        password: ""
    }),


    validate: (values, props) => {
        let errors = {};
        if (!emailRe.test(values.email)) {
            errors.email = "Incorrect email";
        }
        if (!values.password) {
            errors.password = "Incorrect passord";
        }
        return errors;
    },

    handleSubmit: (values, props) => {
        const {dispatch, path, onSuccess, apiPath} = props.props;
        const {setFieldError} = props;

        dataSourceV2(path || "sign_in", {data: {...values, email: values.email.toLowerCase()}, url: apiPath})
            .then(v=>{
                const token = v.access || v.token;
                if (token) {
                    cookie.save("token", token, {path: "/"});
                    if (v.refresh) {
                        cookie.save("token_refresh", v.refresh, {path: "/"});
                    }
                    dispatch("login", {"token": token});
                    props.setSubmitting(false);
                    onSuccess();
                } else {
                    const err = v.message || "";
                    if (err.includes("incorrect email")) {
                        setFieldError("email", "Incorrect email");
                    } else if (err.includes("incorrect password")) {
                        setFieldError("password", "Incorrect password");
                    } else {
                        setFieldError("email", "Try again later");
                    }
                    props.setSubmitting(false);
                }
            });
    }

})(LoginForm);
